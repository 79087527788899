import { useMemo } from 'react';

import { Box, Stack } from '@mui/material';
import { css } from '@mui/system';
import { RouteLink } from '@scorenco/core';

import { IconLoader } from '../../icons';
import { ScorencoLogo } from '../../illustrations';
import { Markdown, Wrapper } from '../../primitives';
import { forceThemeProps } from '../../themes';

import { SportListSliceFragment } from './SportListSlice.fragment.generated';

type SportListSliceProps = {
  slice: SportListSliceFragment;
};

const ALL_SPORTS = {
  football: { name: 'football', icon: 'football', url: '/football' },
  rugby: { name: 'rugby', icon: 'rugby', url: '/rugby' },
  basket: { name: 'basket', icon: 'basket', url: '/basket' },
  hand: { name: 'hand', icon: 'hand', url: '/hand' },
  volley: { name: 'volley', icon: 'volley', url: '/volley' },
  hockey: {
    name: 'hockey sur glace',
    icon: 'hockey-sur-glace',
    url: '/hockey-sur-glace',
  },
  rinkHockey: { name: 'rink hockey', icon: 'rink-hockey', url: '/rink-hockey' },
  rollerHockey: {
    name: 'roller hockey',
    icon: 'roller-hockey',
    url: '/roller-hockey',
  },
};

export const SportListSlice = ({ slice }: SportListSliceProps) => {
  const sports = useMemo(() => {
    if (!slice.sports) {
      return Object.values(ALL_SPORTS);
    }

    const visibleSports = slice.sports
      .trim()
      .split(/\s*,\s*/)
      .map((sport) => ALL_SPORTS[sport as keyof typeof ALL_SPORTS])
      .filter(Boolean);

    return visibleSports.length ? visibleSports : Object.values(ALL_SPORTS);
  }, [slice.sports]);

  return (
    <Wrapper marginY={5}>
      <Stack
        direction={{
          xs: 'column',
          md: 'row',
        }}
        marginX="auto"
        alignItems="center"
        justifyContent="center"
        gap={{
          xs: 3,
          md: 6,
        }}
      >
        {slice.text && (
          <Box
            width={{
              xs: '100%',
              md: '50%',
            }}
          >
            <Markdown value={slice.text} />
          </Box>
        )}

        <Box
          css={css`
            position: relative;
            --button-size: 4rem;
          `}
        >
          <Box
            css={css`
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 0 auto;
            `}
          >
            <Box
              css={(theme) => css`
                display: flex;
                justify-content: center;
                align-items: center;
                border: 4px dashed ${theme.vars.palette.divider};
                border-radius: 50%;
                width: 240px;
                height: 240px;
                margin: calc(var(--button-size) / 2);
              `}
              {...forceThemeProps('light')}
            >
              <ScorencoLogo small sx={{ width: '4.5rem' }} />
            </Box>
          </Box>

          {sports.map((sport, idx) => (
            <RouteLink key={sport.name} href={sport.url}>
              <a
                css={(theme) => css`
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background: linear-gradient(
                    ${theme.vars.palette.background.paper} 0%,
                    ${theme.vars.palette.background.paper} 100%
                  );
                  border-radius: 50%;
                  width: var(--button-size);
                  height: var(--button-size);
                  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.07);
                  text-decoration: none;

                  position: absolute;
                  top: 50%;
                  left: 50%;
                  margin: calc(var(--button-size) / -2);

                  &:hover {
                    background: linear-gradient(
                      45deg,
                      ${theme.vars.palette.secondary.main},
                      ${theme.vars.palette.secondary.light}
                    );
                  }
                  transform: rotate(${220 + (idx * 360) / sports.length}deg)
                    translate(180%)
                    rotate(-${220 + (idx * 360) / sports.length}deg);
                `}
                {...forceThemeProps('light')}
              >
                <IconLoader
                  css={css`
                    display: flex;
                    width: calc(var(--button-size) / 2);
                  `}
                  icon={`sports/${sport.icon}` as any}
                />
              </a>
            </RouteLink>
          ))}
        </Box>
      </Stack>
    </Wrapper>
  );
};
